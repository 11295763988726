

.PvE {
  width: 100%;
}

.Raid {
  min-height: 300px;
  width: 40%;
  min-width: 250px;
  background-color: #414141;
  margin: 10px;
  text-align: center;
  font-family: Semplicita Pro, Open Sans, Arial, Helvetica, sans-serif;
  text-shadow: 1.2px 1.2px #000000;
  color: #f8b700;
  font-size: 24px;
  line-height: 1em;
  font-weight: 700;
  border: 2px solid #252323;
}

.Raid:hover {
  transition: border 0.2s;
  border: 2px solid rgb(247, 166, 26);
}

.Details {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.InstanceHeader {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.InstanceProgress {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
}

.Raids {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  position: relative;
}


.tenman {
  width: 100%;
  margin: 10px;
}

.twentyfiveman {
  width: 100%;
  margin: 10px;
}

.Progressbar-progress {
  background-color: #01b2f1;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 85%;
}

.Progressbar--high>.Progressbar-progress,
.Progressbar--levelColor>.Progressbar-progress {
  background: #1b9601;
  background: -webkit-gradient(linear, left top, right top, from(#0d4a00), to(#1b9601));
  background: linear-gradient(90deg, #0d4a00 0, #1b9601);
}

.Progressbar--high>.Progressbar-progress,
.Progressbar--levelColor:hover>.Progressbar-progress {
  background: #1a9402;
  background: -webkit-gradient(linear, left top, right top, from(#198002), to(#1a9402));
  background: linear-gradient(90deg, #198002 0, #1a9402);
  transition: background 0.25s;
}

.Progressbar--levelColor>.Progressbar-progress[data-progressLevel=medium],
.Progressbar--medium>.Progressbar-progress {
  background: #c76700;
  background: -webkit-gradient(linear, left top, right top, from(#7b3f00), to(#c76700));
  background: linear-gradient(90deg, #7b3f00 0, #c76700);
}

.Progressbar--levelColor:hover>.Progressbar-progress[data-progressLevel=medium],
.Progressbar--medium>.Progressbar-progress {
  background: #cf6b00;
  background: -webkit-gradient(linear, left top, right top, from(#834401), to(#cf6b00));
  background: linear-gradient(90deg, #834401 0, #cf6b00);
}

.Progressbar--levelColor>.Progressbar-progress[data-progressLevel=low],
.Progressbar--low>.Progressbar-progress {
  background: #99755c;
  background: -webkit-gradient(linear, left top, right top, from(#69503f), to(#99755c));
  background: linear-gradient(90deg, #69503f 0, #99755c);
}

.Progressbar--levelColor:hover>.Progressbar-progress[data-progressLevel=low],
.Progressbar--low>.Progressbar-progress {
  background: #a57f63;
  background: -webkit-gradient(linear, left top, right top, from(#705543), to(#a57f63));
  background: linear-gradient(90deg, #705543 0, #a57f63);
}

.Progressbar {
  display: flex;
  align-self: center;
  justify-content: space-between;
  background-color: #181818;
  -webkit-box-shadow: inset 0 0 16px #000;
  box-shadow: inset 0 0 16px #000;
  color: #000;
  position: relative;
  min-height: 28px;
  width: 65%;
}

.Difficulty {
  position: relative;
  display: flex;
  flex-direction: row;
  width: 95%;
  transition: color 0.20s;
  justify-content: space-between;
  
}

.zeroBossKillStatistic {
  color: #9d9d9d;
}

.Difficulty:hover {
  color: white
}

.DifficultyText {
  margin-left: 1.5%;
  margin-right: 1.5%;
  text-align: center;
  font-size: 1rem;
  text-shadow: 0 0 1px transparent, 0 1px 2px rgb(0 0 0 / 80%);
}

.HideDiffStats {
  display: none;
}

.Rankings {
  display: flex;
  font-family: Avenir, Arial, sans-serif;
  font-size: 0.7rem;
  font-weight: bold;
  width: 35px;
  min-width: 35px;
  color: white;
}

.WCLLink {
  text-decoration: none;
}

.tiny-icon {
  width: 14px;
  height: 14px;
  --size: 14;
  margin-right: 5px;
  vertical-align: -2px;
}

.specIcon {
  margin-left: 5px;
  margin-right: 20px;
}


.sprite {
  object-fit: cover;
  border-radius: 6px;
}

.artifact {
  color: #e5cc80 !important;
}

.astounding {
  color: #e268a8 !important;
}

.legendary {
  color: #ff8000 !important;
}

.epic {
  color: #a335ee !important;
}

.rare {
  color: #0070ff !important;
}

.uncommon {
  color: #1eff00 !important;
}

.common {
  color: #666 !important;
}

.InstanceName {
  font-size: 1.5rem;
  margin-top: 18px;
  margin-bottom: 20px;
}

.Progressbar-content {
  font-size: 0.8rem;
  line-height: 1.5;
  font-family: Semplicita Pro, Open Sans, Arial, Helvetica, sans-serif;
  color: #fff;
  text-shadow: 0 0 1px transparent, 0 1px 2px rgb(0 0 0 / 80%);
  font-weight: 700;
  position: relative;
  text-align: center;
  padding: 5px;
  min-height: 1.5em;
}

.Progressbar-border {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border: 1px solid hsla(0, 0%, 100%, .2);
  background: transparent;
  z-index: 1;
}

.Statistics {
  text-align: left;
  margin: 5px;
  background-color: #2e2d2d;
  height: fit-content;
}

.Statistic {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  margin: 2px;
  color: #00FF00;
  font-size: 16px;
  font-weight: 100;
  width: 95%;
}

.VoAImgSmall {
  padding-top: 33.3333%;
  background-repeat: no-repeat;
  background-image: url(../images/PvE/WotLK-Raid/vault-of-archavon-small.jpg);
  background-position: 50%;
  background-size: 100%;
  width: 100%;
  top: 50%;
  left: 50%;
  max-width: 100%;
  height: auto;

}

.NaxxImgSmall {
  padding-top: 33.3333%;
  background-repeat: no-repeat;
  background-image: url(../images/PvE/WotLK-Raid/naxxramas-small.jpg);
  background-position: 50%;
  background-size: 100%;
  width: 100%;
  top: 50%;
  left: 50%;
  max-width: 100%;
  height: auto;

}

.EyeImgSmall {
  padding-top: 33.3333%;
  background-repeat: no-repeat;
  background-image: url(../images/PvE/WotLK-Raid/the-eye-of-eternity-small.jpg);
  background-position: 50%;
  background-size: 100%;
  width: 100%;
  top: 50%;
  left: 50%;
  max-width: 100%;
  height: auto;

}

.OSImgSmall {
  padding-top: 33.3333%;
  background-repeat: no-repeat;
  background-image: url(../images/PvE/WotLK-Raid/the-obsidian-sanctum-small.jpg);
  background-position: 50%;
  background-size: 100%;
  width: 100%;
  top: 50%;
  left: 50%;
  max-width: 100%;
  height: auto;

}

.UlduarImgSmall {
  padding-top: 33.3333%;
  background-repeat: no-repeat;
  background-image: url(../images/PvE/WotLK-Raid/ulduar-small.jpg);
  background-position: 50%;
  background-size: 100%;
  width: 100%;
  top: 50%;
  left: 50%;
  max-width: 100%;
  height: auto;

}

.TotCImgSmall {
  padding-top: 33.3333%;
  background-repeat: no-repeat;
  background-image: url(../images/PvE/WotLK-Raid/trial-of-the-crusader-small.jpg);
  background-position: 50%;
  background-size: 100%;
  width: 100%;
  top: 50%;
  left: 50%;
  max-width: 100%;
  height: auto;

}

.ICCImgSmall {
  padding-top: 33.3333%;
  background-repeat: no-repeat;
  background-image: url(../images/PvE/WotLK-Raid/icecrown-citadel-small.jpg);
  background-position: 50%;
  background-size: 100%;
  width: 100%;
  top: 50%;
  left: 50%;
  max-width: 100%;
  height: auto;

}

.RSImgSmall {
  padding-top: 33.3333%;
  background-repeat: no-repeat;
  background-image: url(../images/PvE/WotLK-Raid/the-ruby-sanctum-small.jpg);
  background-position: 50%;
  background-size: 100%;
  width: 100%;
  top: 50%;
  left: 50%;
  max-width: 100%;
  height: auto;

}

.OnyImgSmall {
  padding-top: 33.3333%;
  background-repeat: no-repeat;
  background-image: url(../images/PvE/WotLK-Raid/onyxias-lair-small.jpg);
  background-position: 50%;
  background-size: 100%;
  width: 100%;
  top: 50%;
  left: 50%;
  max-width: 100%;
  height: auto;

}

.actor-sprite-Deathknight-Blood {
  object-position: calc(-1px*var(--size)) 0px
}

.actor-sprite-Deathknight-Frost {
  object-position: calc(-2px*var(--size)) 0px
}

.actor-sprite-Deathknight-Lichborne {
  object-position: calc(-3px*var(--size)) 0px
}

.actor-sprite-Deathknight-Melee {
  object-position: calc(-4px*var(--size)) 0px
}

.actor-sprite-Deathknight-Runeblade {
  object-position: calc(-5px*var(--size)) 0px
}

.actor-sprite-Deathknight-Unholy {
  object-position: calc(-6px*var(--size)) 0px
}

.actor-sprite-Deathknight {
  object-position: calc(-7px*var(--size)) 0px
}

.actor-sprite-DemonHunter-Havoc {
  object-position: calc(-8px*var(--size)) 0px
}

.actor-sprite-DemonHunter-Vengeance {
  object-position: calc(-9px*var(--size)) 0px
}

.actor-sprite-DemonHunter {
  object-position: calc(-10px*var(--size)) 0px
}

.actor-sprite-Druid-Balance {
  object-position: calc(-11px*var(--size)) 0px
}

.actor-sprite-Druid-Dreamstate {
  object-position: calc(-12px*var(--size)) 0px
}

.actor-sprite-Druid-Feral {
  object-position: calc(-13px*var(--size)) 0px
}

.actor-sprite-Druid-Guardian {
  object-position: calc(-14px*var(--size)) 0px
}

.actor-sprite-Druid-Restoration {
  object-position: calc(-15px*var(--size)) 0px
}

.actor-sprite-Druid-Warden {
  object-position: calc(-16px*var(--size)) 0px
}

.actor-sprite-Druid {
  object-position: calc(-17px*var(--size)) 0px
}

.actor-sprite-Evoker-Devastation {
  object-position: calc(-18px*var(--size)) 0px
}

.actor-sprite-Evoker-Preservation {
  object-position: calc(-19px*var(--size)) 0px
}

.actor-sprite-Evoker {
  object-position: calc(-20px*var(--size)) 0px
}

.actor-sprite-Hunter-BeastMastery {
  object-position: calc(-21px*var(--size)) 0px
}

.actor-sprite-Hunter-Marksmanship {
  object-position: calc(-22px*var(--size)) 0px
}

.actor-sprite-Hunter-Ranged {
  object-position: calc(-23px*var(--size)) 0px
}

.actor-sprite-Hunter-Survival {
  object-position: calc(-24px*var(--size)) 0px
}

.actor-sprite-Hunter {
  object-position: calc(-25px*var(--size)) 0px
}

.actor-sprite-Mage-Arcane {
  object-position: calc(-26px*var(--size)) 0px
}

.actor-sprite-Mage-Fire {
  object-position: calc(-27px*var(--size)) 0px
}

.actor-sprite-Mage-Frost {
  object-position: calc(-28px*var(--size)) 0px
}

.actor-sprite-Mage-Ranged {
  object-position: calc(-29px*var(--size)) 0px
}

.actor-sprite-Mage {
  object-position: calc(-30px*var(--size)) 0px
}

.actor-sprite-Monk-Brewmaster {
  object-position: calc(-31px*var(--size)) 0px
}

.actor-sprite-Monk-Mistweaver {
  object-position: calc(-32px*var(--size)) 0px
}

.actor-sprite-Monk-Windwalker {
  object-position: calc(-33px*var(--size)) 0px
}

.actor-sprite-Monk {
  object-position: calc(-34px*var(--size)) 0px
}

.actor-sprite-NPC {
  object-position: calc(-35px*var(--size)) 0px
}

.actor-sprite-Paladin-Holy {
  object-position: calc(-36px*var(--size)) 0px
}

.actor-sprite-Paladin-Justicar {
  object-position: calc(-37px*var(--size)) 0px
}

.actor-sprite-Paladin-Protection {
  object-position: calc(-38px*var(--size)) 0px
}

.actor-sprite-Paladin-Retribution {
  object-position: calc(-39px*var(--size)) 0px
}

.actor-sprite-Paladin {
  object-position: calc(-40px*var(--size)) 0px
}

.actor-sprite-Pet {
  object-position: calc(-41px*var(--size)) 0px
}

.actor-sprite-Priest-Discipline {
  object-position: calc(-42px*var(--size)) 0px
}

.actor-sprite-Priest-Healing {
  object-position: calc(-43px*var(--size)) 0px
}

.actor-sprite-Priest-Holy {
  object-position: calc(-44px*var(--size)) 0px
}

.actor-sprite-Priest-Shadow {
  object-position: calc(-45px*var(--size)) 0px
}

.actor-sprite-Priest-Smiter {
  object-position: calc(-46px*var(--size)) 0px
}

.actor-sprite-Priest {
  object-position: calc(-47px*var(--size)) 0px
}

.actor-sprite-Rogue-Assassination {
  object-position: calc(-48px*var(--size)) 0px
}

.actor-sprite-Rogue-Combat {
  object-position: calc(-49px*var(--size)) 0px
}

.actor-sprite-Rogue-Melee {
  object-position: calc(-50px*var(--size)) 0px
}

.actor-sprite-Rogue-Outlaw {
  object-position: calc(-51px*var(--size)) 0px
}

.actor-sprite-Rogue-Subtlety {
  object-position: calc(-52px*var(--size)) 0px
}

.actor-sprite-Rogue {
  object-position: calc(-53px*var(--size)) 0px
}

.actor-sprite-Shaman-Elemental {
  object-position: calc(-54px*var(--size)) 0px
}

.actor-sprite-Shaman-Enhancement {
  object-position: calc(-55px*var(--size)) 0px
}

.actor-sprite-Shaman-Restoration {
  object-position: calc(-56px*var(--size)) 0px
}

.actor-sprite-Shaman {
  object-position: calc(-57px*var(--size)) 0px
}

.actor-sprite-TricksOfTheTrade {
  object-position: calc(-58px*var(--size)) 0px
}

.actor-sprite-UnholyFrenzy {
  object-position: calc(-59px*var(--size)) 0px
}

.actor-sprite-Warlock-Affliction {
  object-position: calc(-60px*var(--size)) 0px
}

.actor-sprite-Warlock-Demonology {
  object-position: calc(-61px*var(--size)) 0px
}

.actor-sprite-Warlock-Destruction {
  object-position: calc(-62px*var(--size)) 0px
}

.actor-sprite-Warlock-Ranged {
  object-position: calc(-63px*var(--size)) 0px
}

.actor-sprite-Warlock {
  object-position: calc(-64px*var(--size)) 0px
}

.actor-sprite-Warrior-Arms {
  object-position: calc(-65px*var(--size)) 0px
}

.actor-sprite-Warrior-Champion {
  object-position: calc(-66px*var(--size)) 0px
}

.actor-sprite-Warrior-Fury {
  object-position: calc(-67px*var(--size)) 0px
}

.actor-sprite-Warrior-Gladiator {
  object-position: calc(-68px*var(--size)) 0px
}

.actor-sprite-Warrior-Melee {
  object-position: calc(-69px*var(--size)) 0px
}

.actor-sprite-Warrior-Protection {
  object-position: calc(-70px*var(--size)) 0px
}

.actor-sprite-Warrior-Warden {
  object-position: calc(-71px*var(--size)) 0px
}

.actor-sprite-Warrior {
  object-position: calc(-72px*var(--size)) 0px
}