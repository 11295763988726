
@media (max-width: 520px) {
  .AchievementList {
    margin-left: 0px !important;
    left: -270px;
    top: -25px;
   }
   .Achievements {
    justify-content: normal !important;
   }
   .Categories {
    position: relative;
    left: -80px;
    margin: 0px !important;
    z-index: 2;
   }
   .Category {
    scale: 60%;
    margin-bottom: -14px !important; 
   }
   .SubCategory {
    scale: 60%;
    margin-bottom: -14px !important; 
   }

}

@media (min-width: 521px) and (max-width: 820px) {
  .AchievementList {
    margin-left: 0px !important;
    left: -60px;
    top: -20px;
   }
   .Categories {
    position: relative;
    top: 5px;
    left: 50px;
    margin: 0px !important;
    z-index: 2;
   }
   .Achievements {
    justify-content: normal !important;
   }
   .Category {
    scale: 80%;
    margin-bottom: -10px !important; 
   }
   .SubCategory {
    scale: 80%;
    margin-bottom: -10px !important; 
   }

}

@media (min-width: 821px) and (max-width: 1023px) {
  .AchievementList {
    margin-left: 0px !important;
    left: -30px;
    top: -10px;
   }
   .Categories {
    position: relative;
    top: 10px;
    left: 30px;
    margin: 0px !important;
    z-index: 2;
   }
   .Achievements {
    justify-content: normal !important;
   }
   .Category {
    scale: 85%;
    margin-bottom: -10px !important; 
   }
   .SubCategory {
    scale: 85%;
    margin-bottom: -10px !important; 
   }

}

@media (min-width: 1024px) and (max-width: 1423px) {
  .AchievementList {
    margin-left: 0px !important;
    left: -40px;
    top: -10px;
   }
   .Category {
    margin-bottom: -5px !important; 
   }
   .SubCategory {
    margin-bottom: -5px !important; 
   }
   .Categories {
    position: relative;
    left: 15px;
    margin: 0px !important;
    z-index: 2;
    top: 10px;
   }

}

@media (min-width: 1424px) {
  .AchievementList {
    margin-left: 0px !important;
    left: -40px;
    top: -10px;
   }
   .Category {
    margin-bottom: -5px !important; 
   }
   .SubCategory {
    margin-bottom: -5px !important; 
   }
   .Categories {
    position: relative;
    left: 15px;
    margin: 0px !important;
    z-index: 2;
    top: 10px;
   }
}

.Achievements {
  display: flex;
  position: relative;
  justify-content: space-evenly;
  min-height: 40%;
  max-height: 800px;
}

.AchievementList {
  max-height: 70%;
  min-width: 562px;
  margin-left: 20px;
  position: relative;
}

.Categories {
  margin-top: 7%;
  margin-left: 8%;
  margin-right: 20px;
  width: max-content;
}

.SubCategoryButton {
  position: relative;
  top: -8px;
  margin-left: 20px;
}

.CategoryButtonBG {
  background-position: left;
  background-repeat: no-repeat;
  background-position: -7px -5.5px;
  background-image: url(../images/Achievement/UI-Achievement-Category-Background.png);
  border-radius: 5px;
  width: 158px;
  height: 22px;
  
}

.SubCategoryButtonBG {
  background-position: left;
  background-repeat: no-repeat;
  background-position: -7px -5.5px;
  background-image: url(../images/Achievement/UI-Achievement-Category-Background.png);
  border-radius: 5px;
  width: 158px;
  height: 22px;
  
}


.Category {
  margin-top: 5px;
  height: 25px;
}
.SubCategory {
  margin-left: 20px;
  margin-top: 5px;
  height: 25px;
}

.CategoryTextColor {
  color: yellow;
}

.SubcategoryTextColor{
  color: white;
}



.AllCategoryText {
  position: relative;
  top: -13px;
  text-overflow: ellipsis;
  overflow:hidden; 
  white-space: nowrap;
  text-align: left;
  margin-left: 3px;
  letter-spacing: 0.4px;
  font-family: 'Friz Quadrata Std', sans-serif;
  font-weight: 100;
  text-shadow: 0.7px 0.7px #000000;
  font-size: 12px;
}

.Absolute {
  position: absolute;
  top: 0px;
  left: 6px;
  
}

.Highlight {
  position: absolute;
  left: 3px;
  top: 2px;
  background-position: center;
  background-repeat: repeat;
  background-position: -8px -5px;
  background-image: url(../images/Achievement/UI-Achievement-Category-Highlight.png);
  filter: saturate(79%) brightness(93%);
  border-radius: 2px;
  width: 148px;
  height: 13px;
}


.hover {
  width: 148px;
  height: 13px;
}
.hover:hover {
  background-position: center;
  background-repeat: repeat;
  background-position: -8px -5px;
  background-image: url(../images/Achievement/UI-Achievement-Category-Highlight.png);
  filter: saturate(79%) brightness(93%);
  border-radius: 2px;
  width: 148px;
  height: 13px;
}



