.landing-page {
  display: flex;
  animation: fadeInAnimation ease 2.7s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
  width: 100%;
  min-height: 100vh;
  max-height: fit-content;
  text-shadow: 2px 2px 0px #1212129e, 3px 3px 0px rgba(0, 0, 0, 0.15);
}

.pic {
  position: relative;
  width: 100%;
  left: -13%;
}

@media (max-width: 520px) {

  .fakesection {
    width: 200px !important;
    height: 300px !important;
  }

  .landing-page {
    display: flex;
    flex-direction: column;
    position: relative;
  }

  .ayapic {
    position: relative;
    min-width: 250px !important;
    width: 200px !important;
    height: 300px !important;
  }

  .text-section {
    font-size: 1.9rem;
  }

  .bottom-text {
    font-size: 1rem;
  }

  .landing-page {
    top: 100px;
    justify-content: start;
  }
}

@media (min-width: 521px) and (max-width: 820px) {

  .fakesection {
    width: 200px !important;
    height: 300px !important;
  }

  .landing-page {
    display: flex;
    flex-direction: column;
    position: relative;
  }

  .ayapic {
    position: relative;
    min-width: 250px !important;
    width: 200px !important;
    height: 300px !important;
  }

  .text-section {
    font-size: 1.9rem;
  }

  .bottom-text {
    font-size: 1rem;
  }

  .landing-page {
    top: 100px;
    justify-content: start;
  }

}

@media (min-width: 821px) and (max-width: 1123px) {
  .fakesection {
    width: 200px !important;
    height: 300px !important;
  }

  .text-section {
    font-size: 2.2rem;
    position: relative;
    left: -12%;
  }

  .bottom-text {
    font-size: 1.1rem;
  }

  .pic {
    left: 40px;

  }

  .landing-page {
    justify-content: center;
    left: -100px;
  }


}

@media (min-width: 1124px) and (max-width: 1423px) {
  .fakesection {
    width: 200px !important;
    height: 300px !important;
  }

  .text-section {
    font-size: 2.4rem;
    position: relative;
    left: -12%;
  }

  .bottom-text {
    font-size: 1.2rem;
  }

  .pic {
    left: 40px;
  }

  .landing-page {
    justify-content: center;
  }
}

@media (min-width: 1424px) {
  .fakesection {
    width: 400px !important;
    height: 600px !important;
  }

  .ayapic {
    position: relative;
    width: 400px !important;
    height: 600px !important;
  }

  .text-section {
    font-size: 3rem;
    position: relative;
    left: -12%;
  }

  .bottom-text {
    font-size: 1.5rem;
  }

  .pic {
    left: 40px;
  }

  .landing-page {
    justify-content: center;
  }
}


.pencil {

  font-family: 'Pencil', sans-serif;
  position: relative;
  top: -30px;
  animation-name: move-pencil;
  animation-duration: 1.7s;
  animation-iteration-count: 1;
  animation-timing-function: ease-out;
}


.ayapic {
  position: absolute;
  display: flex;
  flex-direction: row-reverse;
  width: 300px;
  height: 400px;
  padding-right: 30px;
  transition: 2s ease-in-out;
  background-size: cover;
  background-repeat: no-repeat;
  opacity: 0;
  left: 0;
  top: 0;
  transition: opacity 1.5s ease-in-out;
  animation-name: move-picture;
  animation-duration: 1.7s;
  animation-iteration-count: 1;
  animation-timing-function: ease-out;
}

.ayapic2 {
  position: relative;
  flex-direction: row-reverse;
  width: 300px;
  height: 400px;
  display: none;
  padding-right: 30px;
  transition: 2s ease-in-out;
  background-size: cover;
  background-repeat: no-repeat;
  opacity: 0;
  left: 0;
  top: 0;
  transition: opacity 1.5s ease-in-out;
}

.ayapic.active {
  opacity: 1;
}

@keyframes fadeInAnimation {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}


.obiwan-img {
  object-position: 100px 100px;
}

.hello-there {
  width: fit-content;
}

.hello-there:hover {
  background-image: url(https://static.wikia.nocookie.net/worldsgreatestheroes/images/9/91/Obi-Wan_Headshot.jpg/);
  background-size: auto;
  background-clip: text;
  -webkit-background-clip: text;
  color: transparent;
  -webkit-font-smoothing: antialiased;
  background-position: 226px 367px;
  background-size: contain;
  text-shadow: 0px 0px !important;
}

.name:hover {
  transition: color 0.15s;
  color: rgb(206, 123, 64)
}

.title:hover {
  transition: color 0.15s;
  color: rgb(206, 123, 64);

}

#navi {
  background: radial-gradient(#ffffff, rgb(79,221,238));
  width: 10px;
  height: 10px;
  border-radius: 50%;
  left: 0;
  top: 0;
  position: fixed;
  offset-path: path("M100 150C254.752 548.999 575.106 751.654 934.398 848.114 1399.884 1011.831 1651.212 707.406 1517.583 432.185 1340.592 191.477 1222.008 365.813 1233.513 421.566 1332.628 487.937 1208.734 593.247 1246.787 665.813 1621.123 1095.902 823.778 1191.477 629.973 606.521 547.665 265.808 1571.566 811.831 1020.238 242.805 918.468 187.937 521.116-27.112 530.851 214.481 524.663 448.999 1114.037 256.074 1068.019 433.065 652.982 632.185 1232.628 563.159 900.769 755.194 572.451 795.902 594.574 1118.026 392.805 1079.088 174.22 1008.291 241.772 697.672 369.789 456.959 462.709 240.144 899.877 382.622 675.099 559.613 146.78 764.038 178.638 328.64 359.169 14.481 135.276-63.395 55.63 34.835 101.647 149.879");
  animation: move 56s infinite alternate ease-in-out;
  
}
@keyframes move {
  0% {
    offset-distance: 0%;
  }
  100% {
    offset-distance: 100%;
  }
}

@keyframes fly {
  0%{
    transform: translate(10vw,10vh);
  }
  10%{
    transform: translate(20vw,30vh);
  }
  11%{
    transform: translate(21vw,29vh);
  }
  20%{
    transform: translate(30vw,20vh);
  }
  75%{
    transform: translate(10vw,80vh);
  }
  100%{
    transform: translate(0vw,90vh);
  }
}


.text-section {
  margin-left: 1.5%;
  min-width: 220px;
  position: relative;
  animation-name: move-text;
  animation-duration: 1.7s;
  animation-iteration-count: 1;
  animation-timing-function: ease-out;
  top: -30px;
}

@keyframes move-text {
  from {
    top: 10px;
  }

  to {
    top: -30px;
  }
}



@keyframes move-picture {
  from {
    top: 40px;
  }

  to {
    top: 0px;
  }
}

@keyframes move-pencil {
  from {
    top: 10px;
  }

  to {
    top: -30px;
  }
}


.title-word {
  animation: color-animation 4s linear infinite;
}

.title-word-1 {
  --color-1: #F48CBA;
  --color-2: #FFFFFF;
  --color-3: #8788EE;
}

.title-word-2 {
  --color-1: #AAD372;
  --color-2: #0070DD;
  --color-3: #C69B6D;
}

.title-word-3 {
  --color-1: #FF7C0A;
  --color-2: #3FC7EB;
  --color-3: #FFF468;
}

.title-word-4 {
  --color-1: #0a4abf;
  --color-2: #f6eb16;
  --color-3: #FF5500;
}

@keyframes color-animation {
  0% {
    color: var(--color-1)
  }

  32% {
    color: var(--color-1)
  }

  33% {
    color: var(--color-2)
  }

  65% {
    color: var(--color-2)
  }

  66% {
    color: var(--color-3)
  }

  99% {
    color: var(--color-3)
  }

  100% {
    color: var(--color-1)
  }
}

.BG-1 {
  background-image: url(../images/IMG_1999.JPEG);
}

.BG-2 {
  background-image: url(../images/IMG_2004.JPEG);
}

.BG-3 {
  background-image: url(../images/IMG_2050.JPEG);
}

.BG-4 {
  background-image: url(../images/IMG_2182.JPEG);
}

.BG-5 {
  background-image: url(../images/IMG_2211.JPEG);
}

.BG-6 {
  background-image: url(../images/IMG_2258.JPEG);
}

.BG-7 {
  background-image: url(../images/IMG_2339.JPEG);
}

.BG-8 {
  background-image: url(../images/IMG_2545.jpeg);
}

.BG-9 {
  background-image: url(../images/IMG_2792.JPEG);
}

.BG-10 {
  background-image: url(../images/IMG_3566.JPEG);
}

.BG-11 {
  background-image: url(../images/IMG_7701.JPEG);
}