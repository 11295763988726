.About {
  --platinum: #d8dbe2ff;
  --powder-blue: #a9bcd0ff;
  --moonstone: #58a4b0ff;
  --charcoal: #373f51ff;
  --eerie-black: #1b1b1eff;
  --auburn: #a22c29ff;
  --burnt-umber: #902923ff;
  --prussian: #1D2D44;
  width: 100%;
  height: fit-content;
  margin-top: 50px;
  flex-direction: column;
  display: flex;
  position: relative;
  font-family: 'Fjalla One', sans-serif;
  align-items: center;
  scroll-behavior: smooth; 
}

.AboutTextHeaderSection {
  font-size: 4rem;
}

.AboutNameHeader {
  font-size: 5rem;
}

.AboutTextSection {
  font-size: 2.5rem;
  margin-top: 100px;
}

.AboutTextBlock-notopmargin {
  max-width: 70%;
  margin: 45px;
  margin-top: 0px;
  text-align: center;
}
.AboutTextBlock-nobotmargin {
  max-width: 70%;
  margin: 45px;
  margin-bottom: 0px;
  text-align: center;
}

.AboutTextBlock {
  margin: 45px;
  text-align: center;
}

@media (max-width: 520px) {
  .ContentCV {
    max-width: 100%;
  }
  .stage {
    justify-content: center;
  }
}

@media (min-width: 521px) and (max-width: 820px) {
  .ContentCV {
    max-width: 100%;
  }
  .stage {
    justify-content: center;
  }
}

@media (min-width: 821px) and (max-width: 1123px) {
  .ContentCV {
    max-width: 100%;
  }
  .stage {
    justify-content: flex-start;
  }
}

@media (min-width: 1124px) and (max-width: 1423px) {
  .ContentCV {
    max-width: 75%;
  }
  .stage {
    justify-content: flex-start;
  }
}

@media (min-width: 1424px) {
  .ContentCV {
    max-width: 75%;
  }
  .stage {
    justify-content: flex-start;
  }
}

.Header {
  position: relative;
  animation-name: move-text-2;
  animation-duration: 1.7s;
  animation-iteration-count: 1;
  animation-timing-function: ease-out;
  right: 0px;
}

.textfade {
  animation: fadeInAnimation ease 2.7s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}

.ContentCV {
  display: flex;
  align-content: flex-start;
  box-sizing: border-box;
  color: rgba(216, 219, 226, 0);
}

@keyframes move-text-2 {
  from {
    right: 40px;
  }

  to {
    right: 0px;
  }
}

@keyframes move1 {
  from {
    left: 90px;
    color: rgba(216, 219, 226, 0);
  }

  to {
    left: 0px;
    color: rgba(216, 219, 226, 1);
  }
}

@keyframes appear1 {
  from {
    left: 90px;
    color: rgba(255, 124, 10, 0);
  }

  to {
    left: 0px;
    color: rgba(255, 124, 10, 1);
  }
}

@keyframes appear2 {
  from {
    left: 90px;
    color: rgba(63, 199, 235, 0);
  }

  to {
    left: 0px;
    color: rgba(63, 199, 235, 1);
  }
}

@keyframes appear3 {
  from {
    left: 90px;
    color: rgba(170, 211, 114, 0);
  }

  to {
    left: 0px;
    color: rgba(170, 211, 114, 1);
  }
}

@keyframes appear4 {
  from {
    left: 90px;
    color: rgba(255, 244, 104, 0);
  }

  to {
    left: 0px;
    color: rgba(255, 244, 104, 1);
  }
}

@keyframes appear5 {
  from {
    color: rgba(244, 140, 186, 0);
  }

  to {
    color: rgba(244, 140, 186, 1);
  }
}

@keyframes appear6 {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.move1 {
  position: relative;
  animation: move1 ease 1.7s;
  animation-delay: 200ms;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
  left: 90px;
}


.move2 {
  position: relative;
  animation: move1 ease 1.7s;
  animation-delay: 1000ms;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
  left: 90px;
}


.move3 {
  position: relative;
  animation: move1 ease 1.7s;
  animation-delay: 1800ms;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
  left: 90px;
}

.move4 {
  position: relative;
  animation: move1 ease 1.7s;
  animation-delay: 2600ms;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
  left: 90px;
}

.move5 {
  position: relative;
  animation: move1 ease 1.7s;
  animation-delay: 3400ms;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
  left: 90px;
}

.appear1 {
  position: relative;
  animation: appear1 ease 1.7s;
  animation-delay: 200ms;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}


.appear2 {
  position: relative;
  animation: appear2 ease 1.7s;
  animation-delay: 1000ms;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}


.appear3 {
  position: relative;
  animation: appear3 ease 1.7s;
  animation-delay: 1800ms;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}

.appear4 {
  position: relative;
  animation: appear4 ease 1.7s;
  animation-delay: 2600ms;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}

.ProjectsAppear {
  position: relative;
  animation: appear5 ease 1.7s;
  animation-delay: 3400ms;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}

.SpinnerAppear {
  position: relative;
  animation: appear6 ease 1.7s;
  animation-delay: 3800ms;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}

.SpinnerAppear-aboutpage {
  position: relative;
  animation: appear6 ease 1.7s;
  animation-delay: 800ms;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}

@-webkit-keyframes spincube {
  from,to  { -webkit-transform: rotateX(0deg) rotateY(0deg) rotateZ(0deg); }
  16%      { -webkit-transform: rotateY(-90deg);                           }
  33%      { -webkit-transform: rotateY(-90deg) rotateZ(90deg);            }
  50%      { -webkit-transform: rotateY(-180deg) rotateZ(90deg);           }
  66%      { -webkit-transform: rotateY(-270deg) rotateX(90deg);           }
  83%      { -webkit-transform: rotateX(90deg);                            }
}

@keyframes spincube {
  from,to {
    -moz-transform: rotateX(0deg) rotateY(0deg) rotateZ(0deg);
    -ms-transform: rotateX(0deg) rotateY(0deg) rotateZ(0deg);
    transform: rotateX(0deg) rotateY(0deg) rotateZ(0deg);
  }
  16% {
    -moz-transform: rotateY(-90deg);
    -ms-transform: rotateY(-90deg);
    transform: rotateY(-90deg);
  }
  33% {
    -moz-transform: rotateY(-90deg) rotateZ(90deg);
    -ms-transform: rotateY(-90deg) rotateZ(90deg);
    transform: rotateY(-90deg) rotateZ(90deg);
  }
  50% {
    -moz-transform: rotateY(-180deg) rotateZ(90deg);
    -ms-transform: rotateY(-180deg) rotateZ(90deg);
    transform: rotateY(-180deg) rotateZ(90deg);
  }
  66% {
    -moz-transform: rotateY(-270deg) rotateX(90deg);
    -ms-transform: rotateY(-270deg) rotateX(90deg);
    transform: rotateY(-270deg) rotateX(90deg);
  }
  83% {
    -moz-transform: rotateX(90deg);
    -ms-transform: rotateX(90deg);
    transform: rotateX(90deg);
  }
}

.cubespinner {
  -webkit-animation-name: spincube;
  -webkit-animation-timing-function: ease-in-out;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-duration: 12s;

  animation-name: spincube;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
  animation-duration: 12s;

  -webkit-transform-style: preserve-3d;
  -moz-transform-style: preserve-3d;
  -ms-transform-style: preserve-3d;
  transform-style: preserve-3d;

  -webkit-transform-origin: 40px 40px 0;
  -moz-transform-origin: 40px 40px 0;
  -ms-transform-origin: 40px 40px 0;
  transform-origin: 40px 40px 0;
}

.stage {
  width: 80%; 
  height: 80px;
  margin-top: 40px;
  display: flex;
  flex-direction: row-reverse;
  z-index: -9;
  opacity: 0;
}

.stage-aboutpage {
  height: 80px;
  margin-top: 40px;
  display: flex;
  flex-direction: row;
  z-index: -9;
  opacity: 0;
  justify-content: center;
}

.cubespinner div {
  position: absolute;
  width: 80px;
  height: 80px;
  border: 1px solid #ccc;
  background: rgb(56, 46, 46. 0.3);
  box-shadow: inset 0 0 20px rgba(214, 214, 214, 0.795);
  line-height: 80px;
  text-align: center;
  font-size: 50px;
}

.cubespinner .face1 {
  -webkit-transform: translateZ(40px);
  -moz-transform: translateZ(40px);
  -ms-transform: translateZ(40px);
  transform: translateZ(40px);
  width: 80px;
  height: 80px;
}
.cubespinner .face2 {
  -webkit-transform: rotateY(90deg) translateZ(40px);
  -moz-transform: rotateY(90deg) translateZ(40px);
  -ms-transform: rotateY(90deg) translateZ(40px);
  transform: rotateY(90deg) translateZ(40px);
  width: 80px;
  height: 80px;
}
.cubespinner .face3 {
  -webkit-transform: rotateY(90deg) rotateX(90deg) translateZ(40px);
  -moz-transform: rotateY(90deg) rotateX(90deg) translateZ(40px);
  -ms-transform: rotateY(90deg) rotateX(90deg) translateZ(40px);
  transform: rotateY(90deg) rotateX(90deg) translateZ(40px);
  width: 80px;
  height: 80px;
}
.cubespinner .face4 {
  -webkit-transform: rotateY(180deg) rotateZ(90deg) translateZ(40px);
  -moz-transform: rotateY(180deg) rotateZ(90deg) translateZ(40px);
  -ms-transform: rotateY(180deg) rotateZ(90deg) translateZ(40px);
  transform: rotateY(180deg) rotateZ(90deg) translateZ(40px);
  width: 80px;
  height: 80px;
}
.cubespinner .face5 {
  -webkit-transform: rotateY(-90deg) rotateZ(90deg) translateZ(40px);
  -moz-transform: rotateY(-90deg) rotateZ(90deg) translateZ(40px);
  -ms-transform: rotateY(-90deg) rotateZ(90deg) translateZ(40px);
  transform: rotateY(-90deg) rotateZ(90deg) translateZ(40px);
  width: 80px;
  height: 80px;
}
.cubespinner .face6 {
  -webkit-transform: rotateX(-90deg) translateZ(40px);
  -moz-transform: rotateX(-90deg) translateZ(40px);
  -ms-transform: rotateX(-90deg) translateZ(40px);
  transform: rotateX(-90deg) translateZ(40px);
  width: 80px;
  height: 80px;
}
