.HomeContent {
  --platinum: #d8dbe2ff;
  --powder-blue: #a9bcd0ff;
  --moonstone: #58a4b0ff;
  --charcoal: #373f51ff;
  --eerie-black: #1b1b1eff;
  --auburn: #a22c29ff;
  --burnt-umber: #902923ff;
  overflow-y: hidden;
  width: 100vw;
  min-height: 100%;

}

html {
  scroll-padding-top: 50px; /* height of sticky header */
}

.Header {
  font-size: large;
  margin-bottom: 30px;
}

.ArmorySection {
  transform-origin: top;
  background-color: var(--eerie-black);
  width: 100vw;
  height: fit-content;
}
