
.Logo {
  background-repeat: no-repeat;
  background-size: 100%;
  display: inline-block;
  max-width: 100%;
  overflow: hidden;
  width: 50px;
  height: 70px;
  margin-right: 20px;
}

.CompleteDoll {
}

.Logo-HORDE {
  background-image: url(../images/Character/Logo-horde.2a80e0466e51d85c8cf60336e16fe8b8.png);
}

.Logo-ALLIANCE {
  background-image: url(../images/Character/Logo-alliance.bb36e70f5f690a5fc510ed03e80aa259.png);
}

.CharacterHeader {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 30px;
}

.Character {
  display: flex;
  margin-left: 15%;
  margin-right: 15%;
  width: 75%;
  flex-direction: column;
}

.SectionHeader {
  margin-top: 20px;
  margin-bottom: 5px;
  font-family: Semplicita Pro, Open Sans, Arial, Helvetica, sans-serif;
  font-weight: 600;
  font-size: 1.8rem;
  line-height: 1.5;
  line-height: 1em;
  text-align: left;
  color: #f8b700;
}

.CharacterHeader-nameTitle {
  text-rendering: optimizeLegibility;
  display: flex;
  padding-right: 20px;
  margin-right: 20px;
  border-right: 1px solid hsla(0, 0%, 100%, .2);

}

.CharacterHeader-info {
  display: flex;
  flex-direction: row;
  align-items: center;
  color: #f8b700;
}

.CharacterHeader-info-entry {
  display: flex;
  flex-direction: row;
  color: #f8b700;
  font-size: large;
  text-align: top;
  margin-right: 10px;
  vertical-align: middle;
}

.CharacterHeader-info-chardetails {
  display: flex;
  flex-direction: row;
  color: #FFFFFF;
  text-align: top;
  margin-right: 10px;
  vertical-align: middle;
  font-size: 0.7em;
  width: max-content;
}

.CharacterHeader.info.icon {
  display: inline-block;
  fill: #f8b700;
  width: 16px;
  height: 16px;
  background-repeat: no-repeat;
  text-align: center;
}


.CharacterHeader-ilvl {
  margin-left: 2px;
  font-size: 0.8em;
}

.CharacterHeader-nameArea {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.CharacterHeader-name {
  font-family: Semplicita Pro, Open Sans, Arial, Helvetica, sans-serif;
  font-weight: 700;
  font-size: 2.2395rem;
  line-height: 1.5;
  line-height: 1em;
  text-align: left;
}

.Divider {
  margin-top: 15px !important;
  border-bottom: 1px solid hsla(0, 0%, 100%, .2);
  width: 100%;
  position: relative;
}


.CharacterHeader-title {
  color: rgb(232, 230, 227);
  text-shadow: rgb(0 0 0 / 0%) 0px 0px 1px, rgb(0 0 0 / 80%) 0px 1px 2px;
  font-size: 1.08rem;
  line-height: 1.5;
  text-align: left;
  font-family: Semplicita Pro, Open Sans, Arial, Helvetica, sans-serif;
  font-weight: 700;
  max-width: 350px;
}

.CharacterNav {
  display: flex;
  position: relative;
  justify-content: center;
  font-size: 0.65em;
}

@media (max-width: 720px) {

  .ItemSlot {
    margin: 4px;
  }

  .ItemSlotIcon {
    width: 25px;
  }

  .Gem-img {
    visibility: hidden;
  }

  .CharacterHeader-info-chardetails {
    font-size: 0.55em;
  }
  .CharacterHeader-ilvl {
    font-size: 0.6em;
  }
  .Enchant-bottomLeft {
    visibility: hidden;
    height: 0px;
  }
  .Enchant-bottomRight {
    visibility: hidden;
    height: 0px;
  }
  .Enchant-left {
    visibility: hidden;
    height: 0px;
  } 
  .Enchant-right {
    visibility: hidden;
    height: 0px;
  }

  .Logo {
    width: 40px;
    height: 52px;
    margin-right: 10px;
  }

  .CharacterNav-item {
    margin: 0px !important;
  }

  .GemSection-bottomLeft {
    height: 0px !important;
  }
  .GemSection-bottomRight {
    height: 0px !important;
  }

  .GemSection-left {
    height: 0px !important;
  }

  .GemSection-right {
    height: 0px !important;
  }
  .CharacterHeader-nameTitle {
    padding-right: 10px;
    margin-right: 10px;
  }

  .CharacterHeader-title {
    font-size: 0.9rem;
  }

  .CharacterHeader-name {
    font-size: 1.7381rem;
  }

  .CharacterNav {
    font-size: 0.55em;
  }
}

@media (min-width: 621px) and (max-width: 1023px) {

  .CharacterNav {
    font-size: 0.6em;
  }
  .CharacterHeader-title {
    font-size: 1.024rem;
  }

  .CharacterHeader-name {
    font-size: 2.086rem;
  }
  
}

.TooltipSpaceTR {
  width: 10px;
  background-image: url(../images/tooltip.png);
  background-position: top right;
}

.TooltipSpaceBR {
  width: 10px;
  background-image: url(../images/tooltip.png);
  background-position: Bottom right;
}

.Separator {
  display: flex;
  flex-direction: row;
}

.TooltipTop {
  padding-left: 10px;
  padding-top: 10px;
  width: 310px;
  background-image: url(../images/tooltip.png);
  background-position: top left;
}

.TooltipBottom {
  padding-left: 10px;
  padding-bottom: 10px;
  padding-right: 10px;
  width: 310px;
  background-image: url(../images/tooltip.png);
  background-position: bottom left;
}

.TooltipBottomRight {
  background-image: url(../images/tooltip.png);
  background-position: bottom right;
  width: 320px;
}

.CompletePaperdoll {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-top: 20px;
  margin-bottom: 20px;
}

.Paperdoll {
  display: flex;
  justify-content: space-between;
}

.Paperdoll.bottom {
  display: flex;
  justify-content: space-around;
  margin-left: 10%;
  margin-right: 10%;
}


.Table {
  width: 100%;
  display: inline-table;
  vertical-align: inherit
}

.InlineTable {
  display: inline-table;
  vertical-align: inherit;
}


.ItemSection.left {
  display: flex;
  flex-direction: column;

  text-align: left;
}

.ItemSection.right {
  display: flex;
  flex-direction: column;
  text-align: right;
}

.ItemSection.bottomLeft {
  display: flex;
  flex-direction: column;

  text-align: right;
}

.ItemSection.bottomRight {
  display: flex;
  flex-direction: column;

  text-align: left;
}


.ItemSlot {
  display: flex;
  align-items: flex-start;
  margin: 6px;
  color: #9d9d9d;
  font: 1rem "Open Sans", Arial, "Helvetica Neue", Helvetica, sans-serif;
  font-weight: 600;
  height: 98%;
  text-decoration: none;
}


a:hover {
  text-decoration: none;
}

a:link {
  text-decoration: none;
}

.CharacterNav-item {
  font-family: Semplicita Pro, Open Sans, Arial, Helvetica, sans-serif;
  text-transform: uppercase;
  margin: 2px;
  font-weight: 700;
  line-height: 1.5;
  color: #f8b700;
  padding: 6.66666667px 10px;
  background-color: transparent;
  background-repeat: no-repeat;
  text-decoration: none;
  border: none;
  cursor: pointer;
  outline: none;
  -webkit-transition: all .2s cubic-bezier(.23, 1, .32, 1) 0s;
  transition: all .2s cubic-bezier(.23, 1, .32, 1) 0s;
}

.CharacterNav-item:hover {
  background-color: #f8b700;
  color: #211510;
}

.IsSelected {
  background-color: #f8b700;
  color: #211510;
}

.ItemSlot.left {
  flex-direction: row;
  text-align: left;
}

.ItemSlot.right {
  flex-direction: row-reverse;
  text-align: right;
}

.ItemSlot.bottomLeft {
  flex-direction: row-reverse;
  text-align: right;
}

.ItemSlot.bottomRight {
  flex-direction: row;
  text-align: left;
}

.ItemSlotText.left {
  margin-left: 5px;

}
.ItemSlotText.right {
  margin-right: 10px;
  
}
.ItemSlotText.bottomLeft {
  margin-right: 10px;
}
.ItemSlotText.bottomRight {
  margin-left: 5px;
}

.Apart {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

.Enchant-left {
  margin-right: 7px;
  margin-left: 4px;
  position: relative;
  text-align: left;
  font-weight: 200;
  font: 0.6rem "Open Sans", Arial, "Helvetica Neue", Helvetica, sans-serif;
  color: #1eff00 !important;
  text-decoration: none;
  top: -7px;
}

.Enchant-bottomRight {
  margin-right: 7px;
  margin-left: 4px;
  position: relative;
  text-align: left;
  font-weight: 200;
  font: 0.6rem "Open Sans", Arial, "Helvetica Neue", Helvetica, sans-serif;
  color: #1eff00 !important;
  text-decoration: none;
  top: -7px;
}

.Enchant-right {
  margin-right: 7px;
  margin-left: 4px;
  position: relative;
  text-align: right;
  font-weight: 200;
  font: 0.6rem "Open Sans", Arial, "Helvetica Neue", Helvetica, sans-serif;
  color: #1eff00 !important;
  text-decoration: none;
  top: -7px;
}

.Enchant-bottomLeft {
  margin-right: 7px;
  margin-left: 4px;
  position: relative;
  text-align: right;
  font-weight: 200;
  font: 0.6rem "Open Sans", Arial, "Helvetica Neue", Helvetica, sans-serif;
  color: #1eff00 !important;
  text-decoration: none;
  top: -7px;
}

img {
  display: inline !important;
}

.Gem-img {
  border-radius: 17px;
  position: relative;
  top: 12px;
  max-width: fit-content !important;
}

.GemSection-left {
  position: relative;
  padding-left: 2px;
  display: flex;
  flex-direction: row;
  height: 17px;
  width: 68px;
  top: -11px;
}

.GemSection-bottomRight {
  position: relative;
  padding-left: 2px;
  display: flex;
  flex-direction: row;
  height: 17px;
  width: 68px;
  top: -11px;

}

.GemSection-right {
  position: relative;
  padding-left: 2px;
  display: flex;
  flex-direction: row-reverse;
  height: 17px;
  right: 18px;
  top: -11px;

}

.GemSection-bottomLeft {
  position: relative;
  padding-left: 2px;
  display: flex;
  flex-direction: row-reverse;
  height: 17px;
  right: 18px;
  top: -11px;

}

.Gem-left {
  scale: 30%;
  position: relative;
  margin-bottom: 15px;
  bottom: 12px;
  width: 17px;
  height: 17px;
}

.Gem-bottomRight {
  scale: 30%;
  position: relative;
  margin-bottom: 15px;
  bottom: 12px;
  width: 17px;
  height: 17px;
}

.Gem-right {
  scale: 30%;
  position: relative;
  margin-bottom: 15px;
  bottom: 12px;
  width: 17px;
  height: 17px;
}

.Gem-bottomLeft {
  scale: 30%;
  position: relative;
  margin-bottom: 15px;
  bottom: 8px;
  width: 17px;
  height: 17px;
}

.ItemSlot.ItemDetails {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-right: 12px;
  font: 0.7em "Open Sans", Arial, "Helvetica Neue", Helvetica, sans-serif;
  font-weight: 600;
  flex-wrap: wrap;
  margin-top: 0px;

}

.ItemSlot.ItemDetails:hover {
  color: #fff;
}

.ItemSlot.ItemDetails.Legendary {
  color: #ff8000
}

.ItemSlot.ItemDetails.Legendary:hover {
  color: #fff;
}

.ItemSlot.ItemDetails.Epic {
  color: #a335ee;
}

.ItemSlot.ItemDetails.Epic:hover {
  color: #fff;
}

.ItemSlot.ItemDetails.Rare {
  color: #0070dd;
}

.ItemSlot.ItemDetails.Rare:hover {
  color: #fff;
}

.ItemSlot.ItemDetails.Uncommon {
  color: #1eff00;
}

.ItemSlot.ItemDetails.Uncommon:hover {
  color: #fff;
}
.ItemSlot.ItemDetails.Heirloom {
  color: #0cf;
}

.ItemSlot.ItemDetails.Heirloom:hover {
  color: #fff;
}

.ItemSlot.ItemDetails.Common {
  color: #fff;
}

.ItemSlot.ItemDetails.Poor {
  color: #9d9d9d;
}

.ItemSlot.ItemDetails.Poor:hover {
  color: #fff;
}

.itemrow.ItemDetails.Legendary {
  color: #ff8000
}

.itemrow.ItemDetails.Epic {
  color: #a335ee;
}

.itemrow.ItemDetails.Rare {
  color: #0070dd;
}

.itemrow.ItemDetails.Uncommon {
  color: #1eff00;
}

.itemrow.ItemDetails.Common {
  color: #fff;
}

.itemrow.ItemDetails.Poor {
  color: #9d9d9d;
}


.ItemSlotIcon {
  width: 42px;
  border-radius: 4px !important;
  outline: 1.3px solid;
  margin-right: 5px;
}

.ItemSlotIcon:hover {
  box-shadow: 0 0 20px #9ecaed;
}

.ItemSlotIcon.Heirloom {
  color: #0cf;
}
.ItemSlotIcon.Heirloom:hover {
  box-shadow: 0 0 20px #0cf;
  transition: box-shadow 0.15s ease;
}

.ItemSlotIcon.Legendary {
  outline-color: #ff8000;
}

.ItemSlotIcon.Legendary:hover {
  box-shadow: 0 0 20px #ff8000;
  transition: box-shadow 0.15s ease;
}

.ItemSlotIcon.Epic {
  outline-color: #a335ee;
}

.ItemSlotIcon.Epic:hover {
  box-shadow: 0 0 20px #a335ee;
  transition: box-shadow 0.15s ease;
}

.ItemSlotIcon.Rare {
  outline-color: #0070dd;
}

.ItemSlotIcon.Rare:hover {
  box-shadow: 0 0 20px #0070dd;
  transition: box-shadow 0.15s ease;
}

.ItemSlotIcon.Uncommon {
  outline-color: #1eff00;
}

.ItemSlotIcon.Uncommon:hover {
  box-shadow: 0 0 20px #1eff00;
  transition: box-shadow 0.15s ease;
}

.ItemSlotIcon.Common {
  outline-color: #fff
}

.ItemSlotIcon.Common:hover {
  box-shadow: 0 0 20px #fff;
  transition: box-shadow 0.15s ease;
}

.ItemSlotIcon.Poor {
  outline-color: #9d9d9d;
}

.ItemSlotIcon.Poor:hover {
  box-shadow: 0 0 20px #9d9d9d;
  transition: box-shadow 0.15s ease;
}

.moneygold {
  padding-right: 14px;
  padding-top: 18px;
  background: no-repeat right center;
  background-image: url(../images/Money/money-gold.gif);
}

.moneysilver {
  padding-right: 14px;
  padding-top: 18px;
  background: no-repeat right center;
  background-image: url(../images/Money/money-silver.gif);
}

.moneycopper {
  padding-right: 14px;
  padding-top: 18px;
  background: no-repeat right center;
  background-image: url(../images/Money/money-copper.gif);
}

.money {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  text-align: left;
  width: 100%;
  color: white;
}

.itemrow {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  text-align: left;
}

.itemrow.white {
  color: white;
}

.itemrow.classes {
  justify-content: flex-start;
}

.c6 {
  color: #C41E3A;
  justify-content: flex-start;
}

.CharacterHeader-name.CharacterHeader--DEATHKNIGHT {
  color: #C41E3A;
}

.c12 {
  color: #A330C9;
  justify-content: flex-start;
}

.CharacterHeader-name.CharacterHeader--DEMONHUNTER {
  color: #A330C9;
}

.c11 {
  color: #FF7C0A;
}

.CharacterHeader-name.CharacterHeader--DRUID {
  color: #FF7C0A;
}

.c13 {
  color: #33937F;
}

.CharacterHeader-name.CharacterHeader--EVOKER {
  color: #33937F;
}

.c3 {
  color: #AAD372;
}

.CharacterHeader-name.CharacterHeader--HUNTER {
  color: #AAD372;
}

.c8 {
  color: #3FC7EB;
}

.CharacterHeader-name.CharacterHeader--MAGE {
  color: #3FC7EB;
}

.c10 {
  color: #00FF98;
}

.CharacterHeader-name.CharacterHeader--MONK {
  color: #00FF98;
}

.c2 {
  color: #F48CBA;
}

.CharacterHeader-name.CharacterHeader--PALADIN {
  color: #F48CBA;
}

.c5 {
  color: #FFFFFF;
}

.CharacterHeader-name.CharacterHeader--PRIEST {
  color: #FFFFFF;
}

.c4 {
  color: #FFF468;
}

.CharacterHeader-name.CharacterHeader--ROGUE {
  color: #FFF468;
}

.c7 {
  color: #0070dd;
}

.CharacterHeader-name.CharacterHeader--SHAMAN {
  color: #0070dd;
}

.c9 {
  color: #8788EE;
}

.CharacterHeader-name.CharacterHeader--WARLOCK {
  color: #8788EE;
}

.c1 {
  color: #C69B6D;
}

.CharacterHeader-name.CharacterHeader--WARRIOR {
  color: #C69B6D;
}


.itemrow.itemlevelORset {
  color: #ffd100;
}


.itemrow.statrow {
  flex-direction: column;
  align-items: flex-start;
}

.itemrow.statrow.equipstats {
  color: #1eff00;
}

.itemrow.setrow {
  flex-direction: column;
  align-items: flex-start;
  color: #9d9d9d;
}

.itemrow.setrow.setitems {
  padding-left: 0.6em;
}