@import url('https://fonts.googleapis.com/css2?family=Anonymous+Pro:wght@400;700&family=Chakra+Petch:wght@300;400;600&family=Open+Sans:wght@300;400;500;600;700;800&display=swap');
@import url('https://use.typekit.net/ktn2qhx.css');


h1 {
  font-family: brother-1816, sans-serif;
  font-weight: 900;
  text-decoration: none;
}

@media (max-width: 520px) {
  .typing {
    font-size: 1.6rem !important;
  }

  .TypingBox-Timer {
    flex-direction: column;
    align-items: center;
  }

  .TypingDifficulty {
    font-size: 0.9rem !important;
  }
}

@media (min-width: 521px) and (max-width: 820px) {
  .typing {
    font-size: 1.6rem !important;
  }

  .TypingBox-Timer {
    flex-direction: column;
    align-items: center;
  }

  .TypingDifficulty {
    font-size: 1.1rem !important;
  }
}

@media (min-width: 821px) and (max-width: 1123px) {
  .typing {
    font-size: 2rem !important;
  }

  .TypingBox-Timer {
    flex-direction: column;
    align-items: center;
  }

  .TypingDifficulty {
    font-size: 1.8rem !important;
  }

}

@media (min-width: 1124px) and (max-width: 1423px) {
  .typing {
    font-size: 2.2rem !important;
  }

  .TypingDifficulty {
    font-size: 1.6rem !important;
  }

}

@media (min-width: 1424px) {
  .typing {
    font-size: 2.4rem !important;
  }

  .TypingDifficulty {
    font-size: 1.6rem !important;
  }

}

.TypingBox-Timer {
  display: flex;
}

.logo-text {
  margin-left: 20px;
  margin-top: 20px;
  height: fit-content;
  display: flex;
  flex-direction: row;
  font-family: brother-1816, sans-serif;
  text-decoration: none;
  font-size: 45px;
  width: fit-content;
  color: #181d31;
}

.logo-text:hover {
  color: #EAC775;
}

.logo-drip {
  font-weight: 900;
}

.logo-lite {
  font-weight: 400;
}

/* may have issue */
.typing {
  font-size: 60px;
  display: flex;
  white-space: nowrap;
  font-family: courier-prime, monospace;
  margin-left: 33%;
}

.TypingDifficulty {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 20%;
}

.TypingDemo {
  transform-origin: top;
  overflow: hidden;
}

.TypingContainer {
  position: relative;
  top: 20%;
}

.correct {
  color: #e9b12e;
}

.guest-submit {
  font-size: large;
  padding: 10px;
  align-self: center;
}

.mistake {
  color: #8c3d34;
}

.shaken {
  animation: shake 0.4s, turn-red 0.4s;
}

.timer {
  font-size: 40px;
  font-family: brother-1816, sans-serif;
}


tr:nth-child(even) {
  /* background-color: #25173a; */
  /* idk how to do this */
  /* TODO */
}

input {
  padding: 12px 20px;
  font-size: 28px;
  margin: 8px 0;
  box-sizing: border-box;
  color: #EEDCB2;
  background-color: #1C122C;
  border: none;
  outline: 0;
  border-width: 0 0 2px;
  border-bottom: 2px solid #8C3D34;
  margin-left: 33%;
}

input:focus {
  border-color: #8C3D34;
}

input::placeholder {
  opacity: 0.5;
}

.timer-countdown {
  color: #8c3d34;
  animation: shake 0.4s, turn-red 0.4s;
}

.typing-left {
  text-align: right;
  color: #e9b12e;
  white-space: pre;
}

.typing-right {
  text-align: left;
  white-space: pre;
}


.Modal {
  /* background-color: rgba(25, 0, 25, 0.9); */
  margin: auto;
  height: auto;
  width: fit-content;
  display: flex;
  outline: 0;
  font-family: brother-1816, sans-serif;
  font-size: 60px;
  color: #EEDCB2;
  scale: 50%;
}

.Overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.8);
}

.Modal button {
  /* color: #eedcb2; */
  /* background-color: #8c3d34; */
  padding: 20px;
  font-family: brother-1816, sans-serif;
  font-weight: 900;
}

.modal-container {
  padding: 0 20px 80px 100px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  width: 100%;
}

.modal-header {
  font-size: 80px;
}

.testing-info {
  font-size: 25px;
  color: antiquewhite;
}

.navbuttons {
  color: #eedcb2;
}

.display-keyboard {
  display: grid;
  width: 100%;
}

.keyboard-bg,
.keyboard-keys {
  grid-column: 1;
  grid-row: 1;
}

.keyboard-keys {
  z-index: 5;
}

.code-hidden {
  visibility: hidden;
}

.code-hidden {
  visibility: hidden;
}

.code-visible {
  font-family: metalista-web, serif;
  text-align: center;
  font-size: 15px;
  animation: konami 3s;
  color: rgba(0, 0, 0, 0);
  position: absolute;
  top: 50vh;
  left: 40vw;
  right: auto;
  z-index: 99;
}

@keyframes shake {

  10%,
  90% {
    transform: translate(-1px, 0);
  }

  20%,
  80% {
    transform: translate(2px, 4px);
  }

  30%,
  50%,
  70% {
    transform: translate(-4px, -7px);
  }

  40%,
  60% {
    transform: translate(4px, 0);
  }
}

@keyframes turn-red {

  10%,
  90% {
    color: #eedcb2;
  }

  50% {
    color: red;
  }
}

@keyframes konami {

  0% {
    transform: scale(3, 3);
    color: rgba(120, 0, 0, 0);
    letter-spacing: 1px;
  }

  30% {
    transform: scale(4, 4);
    color: rgba(180, 0, 15, 0.9);
  }

  70% {
    transform: scale(5, 5);
    color: rgba(180, 0, 15, 0.9);
  }


  100% {
    transform: scale(8, 8);
    color: rgba(80, 0, 0, 0);
    letter-spacing: 4px;
  }
}

.main-height {
  height: 90;
}