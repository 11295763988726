.PvP {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.HonorKills {
  background-color: #1f1d1d;
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  min-height: 40%;
  max-height: fit-content;
  width: 40%;
  min-width: 250px;
  font-size: 1rem;
  line-height: 1.5;
  font-family: Semplicita Pro, Open Sans, Arial, Helvetica, sans-serif;
  color: #fff;
  text-shadow: 0 0 1px transparent, 0 1px 2px rgb(0 0 0 / 80%);
  font-weight: 700;
  position: relative;
  text-align: center;
  margin: 10px;
  margin-top: 30px;
  margin-bottom: 30px;
  border: 2px solid #252323;
}
.honorkillsdropdown {
  padding-bottom: 10px;
}

.HonorKills:hover {
  transition: border 0.2s;
  border: 2px solid rgb(247, 166, 26);
}

.Bracket {
  background-color: #1f1d1d;
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  min-height: 40%;
  max-height: fit-content;
  width: 40%;
  min-width: 250px;
  font-size: 1rem;
  line-height: 1.5;
  font-family: Semplicita Pro, Open Sans, Arial, Helvetica, sans-serif;
  color: #fff;
  text-shadow: 0 0 1px transparent, 0 1px 2px rgb(0 0 0 / 80%);
  font-weight: 700;
  position: relative;
  text-align: center;
  min-height: 1.5em;
  margin: 10px;
  margin-top: 30px;
  margin-bottom: 30px;
  border: 2px solid #252323;
}

.Bracket:hover {
  transition: border 0.2s;
  border: 2px solid rgb(247, 166, 26);
}

.Title {
  font-size: 1.4rem;
}

.Rating {
  font-size: 1.15rem;
}

.WinLoss {
  font-weight: 200;
}

.ArenaTitle {
  font-size: 1.6rem;
  line-height: 1.8;
}

.ArenaTitle.None {
  padding-bottom: 38.69px;
  color: rgba(255, 255, 255, 0.603);
}

.PVPLogo {
  background-repeat: no-repeat;
  background-size: 100%;
  background-position: center;
  overflow: hidden;
  width: 120px;
  height: 180px;
  margin: 0 auto;
}

.PVPLogo-HORDE {
  background-image: url(../images/Character/Logo-horde.2a80e0466e51d85c8cf60336e16fe8b8.png);
}

.PVPLogo-ALLIANCE {
  background-image: url(../images/Character/Logo-alliance.bb36e70f5f690a5fc510ed03e80aa259.png);
}
.BracketImg {
  width: 100%;

}

.ArenaRankLogo {
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: 100%;
  width: 120px;
  height: 180px;
  margin: 0 auto;
}

.ArenaRank-Elite {
  background-image: url(../images/PvP/Elite.png);

}

.ArenaRank-Duelist {
  background-image: url(../images/PvP/Duelist.png);
}

.ArenaRank-Rival {
  background-image: url(../images/PvP/Rival.png);
}

.ArenaRank-Challenger {
  background-image: url(../images/PvP/Challenger.png);
}

.ArenaRank-Unranked {
  background-image: url(../images/PvP/Unranked.png);
}

.ArenaRank-None {
  background-image: url(../images/PvP/Unranked.png);
  opacity: 0.45;
}
