.Hamburger {
  display: flex;
  flex-direction: row-reverse;
  width: 100%;
  position: fixed;
  margin-right: 4%;
  margin-top: 4%;
  z-index: 99;
}

.fadeOut{
  opacity:0;
  width:0;
  height:0;
  display: none;
}
.fadeIn{
  opacity: 1;
  background: #1f1e1e;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: fixed;
  bottom: 0;
  right: 0;
  left: 0;
  top: 0;
  text-align: left;
  z-index: 90;
  transition: width 0.2s, height 0.2s, opacity 0.2s 0.2s;
}

.Links {
  width: min-content;
  text-align: left;
  color: white !important;
}

.Sidebar {
  --platinum: #d8dbe2ff;
  --powder-blue: #a9bcd0ff;
  --moonstone: #58a4b0ff;
  --charcoal: #373f51ff;
  --eerie-black: #1b1b1eff;
  --auburn: #a22c29ff;
  --burnt-umber: #902923ff;
  height: 101vh;
  width: 50px;
  position: fixed;
  left: 0px;
  background-color: var(--eerie-black);
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  color: var(--platinum);
  z-index: 99;
  top: 0;
}

.bottom-Sidebar-items {
  display: flex;
  flex-direction: column;
  position: relative;
  bottom: 20px;
}

@media screen and (max-width: 524px) {
  .Sidebar {
    --platinum: #d8dbe2ff;
    --powder-blue: #a9bcd0ff;
    --moonstone: #58a4b0ff;
    --charcoal: #373f51ff;
    --eerie-black: #1b1b1eff;
    --auburn: #a22c29ff;
    --burnt-umber: #902923ff;
    height: 101vh;
    width: 0px;
    position: fixed;
    left: 0px;
    background-color: var(--eerie-black);
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    color: var(--platinum);
    z-index: 99;
    top: 0;
  }
}