@media (max-width: 520px) {
  .SpecTree {
    flex-direction: column;
    
  }
  .SpecBoxes {
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
  }
  .SpecBox {
    min-width: 27vw;
    height: 150px !important;
    font-size: 0.7rem !important;
  }

  .SpecIconandName {
    
    flex-direction: column !important;
  }

  .Talents {
    margin: 0px;
  }

}

@media (min-width: 521px) and (max-width: 820px) {
  .SpecTree {
    flex-direction: column;
    
  }
  .SpecBoxes {
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
  }
  .SpecBox {
    width: 33vw;
    height: 150px !important;
  }

  .SpecIconandName {
    flex-direction: column !important;
  }
  .Talents {
    margin: 0px;
  }

}

@media (min-width: 821px) and (max-width: 1023px) {
  .SpecTree {
    flex-direction: column;
   
  }
  .SpecBoxes {
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
  }
  .Talents {
    margin: 0px;
  }

}

@media (min-width: 1024px) and (max-width: 1423px) {
  .SpecTree {
    flex-direction: column;
    
  }
  .SpecBoxes {
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
  }

}

@media (min-width: 1424px) {

  .SpecTree {
    flex-direction: column;
    width: 110%;
    
  }
  .SpecBoxes {
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
  }

}


.SpecBoxes {
  display: flex;
}
.SpecBox {
  display: flex;
  text-align: center;
  flex-direction: row;
  height: 250px;
  width: 250px;
  border-radius: 5px;
  font-family: Semplicita Pro, Open Sans, Arial, Helvetica, sans-serif;
  font-size: 18px;
  line-height: 1em;
  font-weight: 700;
  margin: 20px;
  margin-top: 25px;
}

.SpecBox:hover {
  transition: border 0.2s;
  border: 2px solid rgb(247, 166, 26);
}

.SpecTree {
  display: flex;
  justify-content: center;
  margin-right: 35px;
  overflow: auto;
}

.SpecIconandName {
  display: flex;
  justify-content: space-between;
  min-width: 100%;
  text-align: center;
  flex-direction: row;
}


.SpecNameCount {
  display: flex;
  flex-direction: column;
  margin-top: 10px;
  margin-left: 10px;
  text-shadow: 1.4px 1.4px #000000;
}

.ctc-tree-header {
  align-items: center;
  color: #ccc;
  display: flex;
  gap: 10px;
  padding: 5px 10px;
  position: relative;
}

.Talents {
  display: block;
  height: auto;
  left: 0;
  width: 50%;
  min-width: 750px;
}

.none-talents {
  display: none;
  height: auto;
  width: 50%;
}

.Spec {
  height: 32px;
  width: 32px;
  background-repeat: no-repeat;
  border-radius: 15px;
  margin: 10px;
}

.IsSelected {
}

.BG-DEATHKNIGHT-Blood {
  background-position: center;
  background-image: url(../images/Specs/DeathKnightBlood-TopLeft.png);
}

.BG-DEATHKNIGHT-Frost {
  background-position: center;
  background-image: url(../images/Specs/DeathKnightFrost-TopLeft.png);
}

.BG-DEATHKNIGHT-Unholy {
  background-position: center;
  background-image: url(../images/Specs/DeathKnightUnholy-TopLeft.png);
}

.BG-DRUID-Feral {
  background-position: center;
  background-image: url(../images/Specs/DruidFeralCombat-TopLeft.png);
}

.BG-DRUID-Restoration {
  background-position: center;
  background-image: url(../images/Specs/DruidRestoration-TopLeft.png);
}

.BG-DRUID-Balance {
  background-position: center;
  background-image: url(../images/Specs/DruidBalance-TopLeft.png);
}

.BG-HUNTER-Marksmanship {
  background-position: center;
  background-image: url(../images/Specs/HunterMarksmanship-TopLeft.png);
}

.BG-HUNTER-BeastMastery {
  background-position: center;
  background-image: url(../images/Specs/HunterBeastMastery-TopLeft.png);
}

.BG-HUNTER-Survival {
  background-position: center;
  background-image: url(../images/Specs/HunterSurvival-TopLeft.png);
}

.BG-MAGE-Arcane {
  background-position: center;
  background-image: url(../images/Specs/MageArcane-TopLeft.png);
}

.BG-MAGE-Fire {
  background-position: center;
  background-image: url(../images/Specs/MageFire-TopLeft.png);
}

.BG-MAGE-Frost {
  background-position: center;
  background-image: url(../images/Specs/MageFrost-TopLeft.png);
}

.BG-PALADIN-Retribution {
  background-position: center;
  background-image: url(../images/Specs/PALADINCOMBAT-TOPLEFT.png);
}

.BG-PALADIN-Holy {
  background-position: center;
  background-image: url(../images/Specs/PALADINHOLY-TOPLEFT.png);
}

.BG-PALADIN-Protection {
  background-position: center;
  background-image: url(../images/Specs/PALADINPROTECTION-TOPLEFT.png);
}

.BG-PRIEST-Discipline {
  background-position: center;
  background-image: url(../images/Specs/PriestDiscipline-TopLeft.png);
}

.BG-PRIEST-Holy {
  background-position: center;
  background-image: url(../images/Specs/PriestHoly-TopLeft.png);
}

.BG-PRIEST-Shadow {
  background-position: center;
  background-image: url(../images/Specs/PriestShadow-TopLeft.png);
}

.BG-ROGUE-Assassination {
  background-position: center;
  background-image: url(../images/Specs/RogueAssassination-TopLeft.png);
}

.BG-ROGUE-Combat {
  background-position: center;
  background-image: url(../images/Specs/RogueCombat-TopLeft.png);
}

.BG-ROGUE-Subtlety {
  background-position: center;
  background-image: url(../images/Specs/RogueSubtlety-TopLeft.png);
}

.BG-SHAMAN-Elemental {
  background-position: center;
  background-image: url(../images/Specs/ShamanElementalCombat-TopLeft.png);
}

.BG-SHAMAN-Enhancement {
  background-position: center;
  background-image: url(../images/Specs/ShamanEnhancement-TopLeft.png);
}

.BG-SHAMAN-Restoration {
  background-position: center;
  background-image: url(../images/Specs/ShamanRestoration-TopLeft.png);
}

.BG-WARLOCK-Affliction {
  background-position: center;
  background-image: url(../images/Specs/WarlockCurses-TopLeft.png);
}

.BG-WARLOCK-Demonology {
  background-position: center;
  background-image: url(../images/Specs/WarlockSummoning-TopLeft.png);
}

.BG-WARLOCK-Destruction {
  background-position: center;
  background-image: url(../images/Specs/WarlockDestruction-TopLeft.png);
}

.BG-WARRIOR-Arms {
  background-position: center;
  background-image: url(../images/Specs/WarriorArm-TopLeft.png);
}

.BG-WARRIOR-Fury {
  background-position: center;
  background-image: url(../images/Specs/WarriorFury-TopLeft.png);
}

.BG-WARRIOR-Protection {
  background-position: center;
  background-image: url(../images/Specs/WarriorProtection-TopLeft.png);
}

.Spec-DEATHKNIGHT-Blood {
  background-position: center;
  background-image: url(../images/Specs/Blood.jpg);
}

.Spec-DEATHKNIGHT-Frost {
  background-position: center;
  background-image: url(../images/Specs/FrostDK.jpg);
}

.Spec-DEATHKNIGHT-Unholy {
  background-position: center;
  background-image: url(../images/Specs/Unholy.jpg);
}

.Spec-DRUID-Feral {
  background-position: center;
  background-image: url(../images/Specs/Feral.jpg);
}

.Spec-DRUID-Restoration {
  background-position: center;
  background-image: url(../images/Specs/RestoDruid.jpg);
}

.Spec-DRUID-Balance {
  background-position: center;
  background-image: url(../images/Specs/Balance.jpg);
}

.Spec-HUNTER-Marksmanship {
  background-position: center;
  background-image: url(../images/Specs/Marksmanship.jpg);
}

.Spec-HUNTER-BeastMastery {
  background-position: center;
  background-image: url(../images/Specs/BeastMastery.jpg);
}

.Spec-HUNTER-Survival {
  background-position: center;
  background-image: url(../images/Specs/Survival.jpg);
}

.Spec-MAGE-Arcane {
  background-position: center;
  background-image: url(../images/Specs/Arcane.jpg);
}

.Spec-MAGE-Fire {
  background-position: center;
  background-image: url(../images/Specs/Fire.jpg);
}

.Spec-MAGE-Frost {
  background-position: center;
  background-image: url(../images/Specs/FrostMage.jpg);
}

.Spec-PALADIN-Retribution {
  background-position: center;
  background-image: url(../images/Specs/Ret.jpg);
}

.Spec-PALADIN-Holy {
  background-position: center;
  background-image: url(../images/Specs/HolyPally.jpg);
}

.Spec-PALADIN-Protection {
  background-position: center;
  background-image: url(../images/Specs/ProtPally.jpg);
}

.Spec-PRIEST-Discipline {
  background-position: center;
  background-image: url(../images/Specs/Discipline.jpg);
}

.Spec-PRIEST-Holy {
  background-position: center;
  background-image: url(../images/Specs/HolyPriest.jpg);
}

.Spec-PRIEST-Shadow {
  background-position: center;
  background-image: url(../images/Specs/Shadow.jpg);
}

.Spec-ROGUE-Assassination {
  background-position: center;
  background-image: url(../images/Specs/Assassination.jpg);
}

.Spec-ROGUE-Combat {
  background-position: center;
  background-image: url(../images/Specs/Combat.jpg);
}

.Spec-ROGUE-Subtlety {
  background-position: center;
  background-image: url(../images/Specs/Subtlety.jpg);
}

.Spec-SHAMAN-Elemental {
  background-position: center;
  background-image: url(../images/Specs/Elemental.jpg);
}

.Spec-SHAMAN-Enhancement {
  background-position: center;
  background-image: url(../images/Specs/Enhancement.jpg);
}

.Spec-SHAMAN-Restoration {
  background-position: center;
  background-image: url(../images/Specs/RestoShaman.jpg);
}

.Spec-WARLOCK-Affliction {
  background-position: center;
  background-image: url(../images/Specs/Affliction.jpg);
}

.Spec-WARLOCK-Demonology {
  background-position: center;
  background-image: url(../images/Specs/Demonology.jpg);
}

.Spec-WARLOCK-Destruction {
  background-position: center;
  background-image: url(../images/Specs/Destruction.jpg);
}

.Spec-WARRIOR-Arms {
  background-position: center;
  background-image: url(../images/Specs/Arms.jpg);
}

.Spec-WARRIOR-Fury {
  background-position: center;
  background-image: url(../images/Specs/Fury.jpg);
}

.Spec-WARRIOR-Protection {
  background-position: center;
  background-image: url(../images/Specs/ProtectionWarrior.jpg);
}

.c6 {
  color: #C41E3A;
  justify-content: flex-start;
}

.CharacterHeader--DEATHKNIGHT {
  color: #C41E3A;
}

.c12 {
  color: #A330C9;
  justify-content: flex-start;
}

.CharacterHeader--DEMONHUNTER {
  color: #A330C9;
}

.c11 {
  color: #FF7C0A;
}

.CharacterHeader--DRUID {
  color: #FF7C0A;
}

.c13 {
  color: #33937F;
}

.CharacterHeader--EVOKER {
  color: #33937F;
}

.c3 {
  color: #AAD372;
}

.CharacterHeader--HUNTER {
  color: #AAD372;
}

.c8 {
  color: #3FC7EB;
}

.CharacterHeader--MAGE {
  color: #3FC7EB;
}

.c10 {
  color: #00FF98;
}

.CharacterHeader--MONK {
  color: #00FF98;
}

.c2 {
  color: #F48CBA;
}

.CharacterHeader--PALADIN {
  color: #F48CBA;
}

.c5 {
  color: #FFFFFF;
}

.CharacterHeader--PRIEST {
  color: #FFFFFF;
}

.c4 {
  color: #FFF468;
}

.CharacterHeader--ROGUE {
  color: #FFF468;
}

.c7 {
  color: #0070dd;
}

.CharacterHeader--SHAMAN {
  color: #0070dd;
}

.c9 {
  color: #8788EE;
}

.CharacterHeader--WARLOCK {
  color: #8788EE;
}

.c1 {
  color: #C69B6D;
}

.CharacterHeader--WARRIOR {
  color: #C69B6D;
}