@tailwind base;
@tailwind components;
@tailwind utilities;


.App {
  /* CSS HEX */
  margin-top: 0;
--platinum: #d8dbe2ff;
--powder-blue: #a9bcd0ff;
--moonstone: #58a4b0ff;
--charcoal: rgb(31, 34, 41);
--eerie-black: #1b1b1eff;
--auburn: #a22c29ff;
--burnt-umber: #902923ff;
  height: auto;
  width: 100%;
  overflow: hidden;
  background-color: var(--charcoal);
  font-family: 'Fjalla One', sans-serif;
  max-width: fit-content;
  height: 100%;
}

img {
  max-width: fit-content;
}

