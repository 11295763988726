@media (max-width: 520px) {
  .Achievement {
    scale: 40%;
    margin-bottom: -58px !important;
  }
}

@media (min-width: 521px) and (max-width: 820px) {
  .Achievement {
    scale: 53%;
    margin-bottom: -47px !important;
  }

}

@media (min-width: 821px) and (max-width: 1023px) {
  .Achievement {
    scale: 63%;
    margin-bottom: -38px !important;
  }
}

@media (min-width: 1024px) and (max-width: 1423px) {
  .Achievement {
    scale: 70%;
    margin-bottom: -31px !important;
  }
}

@media (min-width: 1424px) {
  .Achievement {
    scale: 70%;
    margin-bottom: -31px !important;
  }
}

.Achievement {
  width: 540px;
  height: 88px;
  position: relative;
  font-family: 'Friz Quadrata Std', sans-serif;
  border: 2px solid rgb(133, 28, 9);
  border-radius: 4px;
  margin: 8px;
  max-width: inherit !important;
}

.Achievement.notEarned {
  width: 540px;
  height: 84px;
  position: relative;
  font-family: 'Friz Quadrata Std', sans-serif;
  border: 2px solid rgb(59, 59, 59);
  border-radius: 4px;
  margin: 3px;
}


.AchievementBackground {
  color: white;
  position: relative;
  width: 540px;
  height: 84px;
  text-align: center;
  background-image: url(../images/Achievement/UI-Achievement-Parchment-Horizontal.png);
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100%;

}

.AchievementBackgroundNotEarned {
  color: white;
  position: relative;
  width: 540px;
  height: 84px;
  text-align: center;
  background-image: url(../images/Achievement/UI-Achievement-Parchment-Horizontal-Desaturated.png);
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100%;

}

.Achievement:hover {
  border: 2px solid rgb(247, 166, 26);
}

.AchData {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.MediaBorder {
  position: relative;
  scale: 115%;
  bottom: 65px;
  right: 5px;
}


.AchNameDesc {
  position: relative;
  display: flex;
  text-align: center;
  width: 360px;
  flex-direction: column;
  opacity: 1;
  color: white;
}

.AchName {
  font-size: 15px;
  position: relative;
  opacity: 1;
  height: 24px;
  width: 100%;
  top: 1px;
  font-weight: 100;
  text-shadow: 0.7px 0.7px #000000;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  letter-spacing: 0.4px;
}

.AchName.NotEarned {
  color: rgb(177, 172, 172);
}

.AchDesc {
  position: relative;
  color: #000000;
  top: 3px;
  height: 40px;
  overflow: hidden;
  font-size: small;

}

.AchTitleBackground {
  opacity: 0.85;
  max-width: 512px;
  height: 21px;
  overflow: hidden;

}

.TsunamiTop {
  position: relative;
  bottom: 7.5px;
  max-width: 540px;
  opacity: 0.2;
  transform: rotate(180deg);
}

.TsunamiBottom {
  max-width: 540px;
  position: relative;
  top: 31px;
  opacity: 0.3;
}

.Reward {
  max-width: 540px;
  align-items: center;
  position: absolute;
  scale: 110%;
  top: 66px;
  left: 100px;
  opacity: 1;
}

.RewardText {
  position: relative;
  top: 3px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  font-size: small;

}

.RewardText.NotEarned {
  color: rgb(177, 172, 172);

}


.Media {
  position: relative;
  top: 10px;
  left: 10px;
  width: 90px;
}

.NotEarned {
  filter: saturate(20%);
}



.AchPointsDate {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 90px;
  position: relative;
  left: 10px;
  text-align: center;

}

.Date {
  position: relative;
  color: rgb(255, 251, 32);
  font-size: 11px;
  bottom: 16px;
  letter-spacing: 0.6px;
}

.Date.feat {
  position: relative;
  color: rgb(255, 251, 32);
  font-size: 11px;
  bottom: 0px;
  letter-spacing: 0.6px;
}

.Points {
  position: relative;
  font-size: 14px;
  bottom: 31px;
  left: -1px;
  color: white;
}

.AchShield {
  position: relative;
  top: 6px;
}

.AchShield.NotEarned {
  filter: saturate(0%) brightness(60%);
}