.Projects {
  --platinum: #d8dbe2ff;
  --powder-blue: #a9bcd0ff;
  --moonstone: #58a4b0ff;
  --charcoal: #373f51ff;
  --eerie-black: #1b1b1eff;
  --auburn: #a22c29ff;
  --burnt-umber: #902923ff;
  display: flex;
  flex-direction: column;
  text-decoration: none;
  min-height: 100vh;
}

#Projects {
  font-family: 'Fjalla One', sans-serif;
  font-size: 3rem;
  color: var(--platinum);
  position: relative;
  width: 100vw;
  animation: fadeInAnimation ease 1.8s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
  
}

#About {
  font-family: 'Fjalla One', sans-serif;
  font-size: 3rem;
  color: var(--platinum);
  min-height: 100vh;
}

.Proj {
  display: flex;
  justify-content: center;
  font-size: large;
  font-family: 'Fjalla One', sans-serif;
}

.SlideSelect {
  display: flex;
}