.Slide {
  --platinum: #d8dbe2ff;
  --powder-blue: #a9bcd0ff;
  --moonstone: #58a4b0ff;
  --charcoal: #373f51ff;
  --eerie-black: #1b1b1eff;
  --auburn: #a22c29ff;
  --burnt-umber: #902923ff;
  --prussian: #1D2D44;
  display: flex;
  flex-direction: column;
  position: relative;
}

.SlideText {
  margin-top: 6px;
  white-space: pre-line;
  overflow-y: auto;
  font-size: calc(12px + 0.23vw);
  overflow-x: auto;
  font-family: Semplicita Pro, Open Sans, Arial, Helvetica, sans-serif;
  word-wrap: break-word;
}

.loginButton {
  background-color: #1ED760 !important;
  color: #000 !important;
  box-sizing: border-box !important;
  font-family: "CircularSpUIv3T-Black", Helvetica, Arial, sans-serif !important;
  -webkit-tap-highlight-color: transparent !important;
  font-size: 20px !important;
  line-height: 20px !important;
  font-weight: 200 !important;
  height: 50px !important;
  width: max-content !important;
  border: 0px !important;
  border-radius: 500px !important;
  display: inline-block !important;
  position: relative !important;
  text-align: center !important;
  text-decoration: none !important;
  text-transform: none !important;
  touch-action: manipulation !important;
  transition-duration: 33ms !important;
  transition-property: background-color, border-color, color, box-shadow, filter, transform !important;
  user-select: none !important;
  vertical-align: middle !important;
  transform: translate3d(0px, 0px, 0px) !important;
  padding: 18px !important;
  padding-top: 4px !important;
  padding-bottom: 4px !important;
  margin-right: 8px !important;
  align-self: center !important;
  text-outline: 1px black solid !important;
}

.loginbutton:hover {
  background-color: #20e967 !important;
  transition: background-color 150ms linear !important;
  cursor: pointer !important;
}

a:visited { 
  text-decoration: none; 
 }


.SlideDemo {
  width: 55vw;
  display: flex;
  flex-direction: row;
}

@media (max-width: 620px) {
  .Slide {
    flex-direction: column;
  }

  .SlideText{
    width: 95vw;
    padding: 5vw;
  }

  .SlideDemo {
    width: 100vw !important;
  }
}

@media (min-width: 621px) and (max-width: 1023px) {
  .Slide {
    flex-direction: column;
  }

  .SlideText{
    width: 95vw;
    padding: 4vw;
    padding-top: 1vw;
  }

  .SlideDemo {
    width: 100vw !important;
  }
}

@media (min-width: 1024px) and (max-width: 1423px) {
  .Slide {
    flex-direction: row;
  }

  .SlideText{
    width: 40vw;
    padding: 4vw;
    padding-top: 1vw;
  }
}

@media (min-width: 1424px) {
  .Slide {
    flex-direction: row;
  }
  .SlideDemo {
    width: 55vw;
  }

  .SlideText{
    width: 40vw;
    padding: 4vw;
    padding-top: 1vw;
  }

}

