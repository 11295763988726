variables {
  --platinum: #d8dbe2ff;
  --powder-blue: #a9bcd0ff;
  --moonstone: #58a4b0ff;
  --charcoal: #373f51ff;
  --eerie-black: #1b1b1eff;
  --auburn: #a22c29ff;
  --burnt-umber: #902923ff;
}
.Nav {
  width: 100%;
  height: 5vh;
  background-color: var(--eerie-black);
  display: flex;
  position: fixed;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  align-content: center;
  z-index:99;
  color: var(--platinum)
}

.Name {
  margin-left: 5vh;
}

.NameBig {
  font-size: 2em;
}

.NameSmall {
  font-size: 1.5em;
}

.Nav-items {
  display: flex;
  flex-direction: row;
  text-decoration: none;
  
}

.Nav-item {
  margin-right: 80px;
  text-decoration: none;
  color: var(--platinum)
}

.Nav-item:hover {
  color: var(--auburn)
}